/* eslint-disable */
import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_BASE_URL
// const appUrl = process.env.VUE_APP_BASE_URL

export const registerUser = ({commit}, user) => {
    return new Promise((resolve, reject) => {
        return axios.post(apiUrl + '/api/auth/register', user).then(response => {
            commit('READY_TO_CONTINUE', true)
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

// export const initStateProfile = ({commit}, profile) => {
//     commit('INIT_PROFILE', profile)
// }

// immediate after registration
export const setProfileData = ({commit}, profile) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/profile', profile).then((response) => {
            // console.log('Profile Data');
            // console.log(response.data)
            commit('SET_CUSTOMER_NUMBER', 1001)
            resolve(response)
        }).catch((error) => {
            // console.log('ACTIONS: Profile Settings Error')
            reject(error)
        })
    })
}

// in Profile plugin routes
export const getUserProfile = ({commit}, uid) => {
    // console.log('Action getUserProfile')
    return new Promise((resolve, reject) => {
        axios.get(apiUrl + '/profile/' + uid).then((response) => {
            // console.log('response-data[0]-upload_folder in action');
            // console.log(response.data[0].upload_folder)
            const profile = response.data[0]
            commit('SET_PROFILE', profile)
            commit('SET_AUTHORIZED', true)
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const loginUser = ({commit}, user) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/api/auth/login', user).then(response => {
            commit('SET_TOKEN', response.data.token)
            commit('SET_USER', response.data.user)
            commit('SET_AUTHORIZED', true)
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

// Forgot Password axios.post(apiUrl + '/api/auth/login', data).then(response => {
export const forgotPassword = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/api/auth/forgot-password', data).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

// to update user table  and profile table in DB
export const updateUser = ({commit, state}, payload) => {
    // console.log('Action check ID: ' + payload.id)
    // console.log(payload)
    const authorization = {
        headers: {
            'Authorization': 'Bearer ' + state.token
        }
    }

    commit('SET_ERROR', null)
    return axios.post(apiUrl + '/update-account', payload, authorization).then(response => {
        // console.log('..........RESPONSED...............');
        // console.log(response.data)
    }).catch(error => {
        commit('SET_ERROR', error.response)
        // console.log(error.response)
    })
}

export const logout = ({commit}) => {
    commit('SET_AUTHORIZED', false)
    commit('SET_USER', {})
    commit('SET_TOKEN', '')
    commit('SET_PROFILE', null)
}

export const deleteProfile = ({commit}, profile) => {
    return axios.post(apiUrl + '/delete-profile', profile).then((response) => {
        commit('SET_PROFILE', null)
    })
}

export const deleteUser = ({commit, state}, user) => {
    const authorization = {
        headers: {
            'Authorization': 'Bearer ' + state.token
        }
    }
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/delete-user', user, authorization).then((response) => {
            resolve(response)
            commit('SET_USER', {})
            // console.log(response.data)
        }).catch(error => {
            reject(error)
            // console.log(error.response)
        })
    })
}

export const checkRegistrationAllowed = ({commit}, initial) => {
    axios.get(apiUrl + '/vtc-net/isRegAllowed.php', {
        params: {
            data: initial
        }
    }).then((response) => {
        // console.log('checkReg...: ' + response);
        let status = false
        if (response.data === 'yes') {
            status = true
        }
        commit('SET_ALLOW_REGISTER', status)
    }).catch((error) => {
        console.log(error);
    })
}

// FE only
export const setUserLogFilename = ({commit}, name) => {
    commit('SET_USER_LOG_FILENAME', name)
}

export const createUserLogPath = ({commit}, filename) => {
    axios.get(apiUrl + '/vtc-net/createUserLogFile.php', {
        params: {
            data: filename
        }
    }).then((response) => {
        if (response.data === 'created') {
            console.log(response.data)
            commit('SET_USER_LOG_FILE_CREATED', true)
        }
    }).catch((error) => {
        console.log(error.response);
    })
}

export const addLogEntry = ({commit}, data) => {
    axios.get(apiUrl + '/vtc-net/addLogEntry.php', {
        params: {
            data: data
        }
    }).then((response) => {
        console.log('Add LOG ENTRY');
        console.log(response.data);
    }).catch((error) => {
        console.log(error)
        commit('SET_USER_LOG_FILE_LENGTH', -1)
    })
}

export const getUserRegistered = ({commit}, email) => {
    axios.get(apiUrl + '/vtc-net/isUserRegistered.php', {
        params: {
            data: email
        }
    }).then((response) => {
        let status = false
        if (response.data === 'yes') {
            status = true
        }
        console.log(response.data);
        commit('SET_IS_REGISTERED', status)
    }).catch((error) => {
        console.log(error.response.error.data)
    })
}

export const initState = ({commit}, status) => {
    if (status === true) {
        commit('INIT_STATE_PARAMS')
    }
}


