<template>
  <div id="app">
    <navigation></navigation>
    <router-view></router-view>
    <div id="page-footer">
      <Footer></Footer>
    </div>
  </div>

</template>

<script>
/* eslint no-undef: 0 */

import M from 'materialize-css'
import Navigation from '@/views/components/Navigation'
import Footer from '@/views/components/Footer'

export default {
  name: 'App',
  data() {
    return {
      //
    }
  },
  components: {
    Navigation,
    Footer
  },
  mounted () {
    M.AutoInit()
    // document.addEventListener('DOMContentLoaded', function () {
    //   M.AutoInit()
    // })

    $('#app').click(function () {
      $('.sidenav').sidenav('close')
      $('.sidenav-overlay').css('display', 'none')
    })
  },
  methods: {
    closeOverlay () {
      $('.sidenav').sidenav('close')
      $('.sidenav-overlay').css('display', 'none')
    }
  }
}
</script>

<style lang="scss">

</style>
