import UploadForm from '@/components/upload/UploadForm'

export default [
    {
        path: 'upload',
        component: UploadForm,
        name: 'upload',
        meta: {
            guest: false,
            needsAuth: true
        }
    }
]
