import axios from 'axios'
//  apiUrl: https://lng.vtc-net.de/api   on production
const apiUrl = process.env.VUE_APP_API_BASE_URL
// appUrl: https://lng.vtc-net.de
// const appUrl = process.env.VUE_APP_BASE_URL

// To create datePath if not exists. datePath is the ABSOLUTE PATH where to store data .
export const setDatePath = ({commit}, path) => {
    // absolute sys. path for upload folder e.g. /var/www/html/al-lng/api/uploads/jarrar000002/240503
    return new Promise((resolve, reject) => {
        // vtc-net/doc plugin contain set-path route
        axios.post(apiUrl + '/set-path', path)
            .then((response) => {
                commit('SET_DATE_PATH', path.path) // path.path == response.data
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export const uploadFiles = ({ commit, state }, fd) => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/uploader.php', fd, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
      .then((response) => {
        resolve(response)
        console.log(response.data)
        console.log(response.status)
        state.files = []
        commit('STORE_UPLOADED_FILES', response.data.uploaded)
      })
      .catch(error => {
        reject(error)
        // console.log('Something went wrong !')
        // console.log(error)
      })
  })
}

