<template>
  <div>
    <pdf :src="path"></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'ViewPdf',
  data () {
    return {
      //
    }
  },
  components: {
    pdf
  },
  props: {
    filename: {
      type: String,
      default: ''
    }
  },

  computed: {
    path () {
      return './static/' + this.filename
    }
  }
}
</script>

<style scoped>

</style>
