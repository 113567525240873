export default {
    computed: {
        userUploadPath() {
            return this.$store.getters['auth/profile'].upload_folder
        }
    },
    methods: {
        getDateFolder() {
            let currentDate = new Date()
            return currentDate.getFullYear().toString().slice(-2) +
                ('0' + (currentDate.getMonth() + 1)).slice(-2) +
                ('0' + (currentDate.getDate())).slice(-2)
        }
    }
}
