
export default {
    methods: {
        buildUserLogFilename(uid) {
            let base = '000000'
            let tail = base + uid
            tail = tail.substr(tail.length - 6) // five digit string with 0 leading
            return this.surname.toLowerCase().replace(/\s/g, "") + tail + '.' + 'log'
        },
        addLogEntry(message) {
            // alert('Add Log Entry')
            const filename = this.$store.getters['auth/getUserLogFilename']
            let data = "filename" + "=" + filename + "&" + "message" + "=" + message
            this.$store.dispatch('auth/addLogEntry', data).then(()=>{
                // user log entry has been done
            })
        }
    }
}
