
export const authorized = (state) => state.authorized

export const user = (state) => state.user

export const profile = (state) => state.profile

export const token = (state) => state.token

export const isRegisteringAllowed = state => state.isReadyToRegister

export const getUserLogFileCreated = state => state.isUserLogFileCreated

export const getUserLogFilename = state => state.userLogFilename

export const getUserLogFileLines = state => state.logFileLineLength

