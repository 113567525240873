import axios from 'axios'

// const apiUrl = process.env.VUE_APP_API_BASE_URL
const appUrl = process.env.VUE_APP_BASE_URL
/*
export const sendContactForm = ({ commit }, contact) => {
    // console.log(contact)  // ok
    return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/send-contact-mail', contact)
            .then((response) => {
                // console.log('SUCCESS')
                // console.log(response.data)
                resolve(response)
            }).catch((error) => {
            // console.log('ERROR')
            // console.log(error)
            reject(error)
        })
    })
}
*/
export const downloadFile = ({ commit }, filename) => {
    let url = appUrl + '/static/' + filename
    axios.get(url, { responseType: 'blob' })
        .then(response => {
            const headers = response.headers
            let blob = new Blob([response.data], { type: headers['content-type'] })
            // 'a': node for anchor element
            commit('SET_DOWNLOADED_FILE', filename)
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
            link.remove()
        })
        .catch((err) => {
            // alert(this.$i18n.t('download_err'))
            console.log(err);
        })
}
