<template>
  <div>
    <a class="waves-effect btn orange lighten-2 grey-text text-darken-4" @click="logout">
      <i class="material-icons right">directions_walk</i>
      {{ $t('footer.logout') }}
    </a>
  </div>
</template>

<script>
import userLogFile from "@/mixins/userLogFile";
export default {
  name: 'Logout',
  data() {
    return {
      uid: this.$store.getters['auth/user'].id,
      surname: this.$store.getters['auth/user'].surname
    }
  },

  methods: {
    logout() {
      // this.uid = this.$store.getters['auth/user'].id
      this.userLogFilename = this.buildUserLogFilename(this.uid)
      this.$store.dispatch('auth/setUserLogFilename', this.userLogFilename)
      this.addLogEntry("Logged out ............")
      this.$emit('log-out')
      this.$emit('close-expanded')
      scroll(0,0)
      this.$store.dispatch('auth/initState', true).then(() => {
        // init store auth/state to null
      })
    }
  },
  mixins: [userLogFile]
}
</script>

<style scoped>

</style>
