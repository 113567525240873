<template>
  <div id="modal-forgot" class="modal">
    <div class="modal-content">
      <h4 class="grey-text text-darken-2">{{ $t('auth.require_password') }}</h4>
      <form id="forgot-form" role="form" @submit.prevent="forgotPassword">
        <div class="input-field">
          <input type="email" id="forgot-email" class="validate" v-model="email" required/>
          <label for="forgot-email">Email</label>
        </div>
        <button type="submit" class="btn orange lighten-2 z-depth-0">{{ $t('auth.send') }} <i class="material-icons" style="vertical-align: -5px;">send</i></button><span class="transparent-text">&nbsp;&nbsp;</span>
        <a id="close-login" class="modal-action modal-close btn grey z-depth-0" @click="closeOverlay">
          <i class="material-icons" style="vertical-align: -5px;">cancel</i>&nbsp;{{ $t('auth.cancel') }}
        </a>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
export default {
  name: 'Forgot',
  data () {
    return {
      responseData: null,
      email: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      $('#modal-forgot').modal({
        dismissible: false,
        inDuration: 500,
        outDuration: 500
      })
    })
  },
  methods: {
    closeOverlay () {
      this.$emit('onCloseOverlay')
    },
    async forgotPassword () {
      const user = new URLSearchParams()
      const modal = document.querySelector('#modal-forgot')
      user.append('email', this.email)
      await this.$store.dispatch('auth/forgotPassword', user).then((response) => {
        this.responseData = response.data
        alert(this.$i18n.t('nav.check_email_box'))
      }).catch((error) => {
        this.error = error.response.data.error
        if (this.error === 'invalid_credentials') {
          alert(this.$i18n.t('footer.error_email'))
        }
      })
      M.Modal.getInstance(modal).close()
    }
  }
}
</script>

<style scoped>

</style>
