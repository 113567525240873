<template>
  <!-- this component is allowed, if the user is logged in ## see ## Navigation ##  -->
  <!-- if router-link is clicked, it load the UploadForm component per ## Upload/index.js route ##
       show the UploadForm.vue component  and create the datePath for the logged in user -->
  <router-link  :to="`/${$i18n.locale}/upload`"
     @click.native="setDatePath"
     class="tooltipped"
     data-position="bottom" :data-tooltip="$t('nav.upload_prepare')"
     data-target="mobile-links"
  >
    <i class="material-icons orange-text text-lighten-2">publish</i><span id="upbload-publisch">{{ $t('upload.to_upload') }}</span>
  </router-link>
</template>

<script>
/* eslint-disable */

import dateFolder from '@/mixins/dateFolder'

export default {
  name: 'UploadStarter',
  data(){
    return {
      datePath: ''
    }
  },
  mounted() {
    //
  },

  computed: {
    // to mixins ->>>> userUploadPath >>> getDateFolder
  },

  methods: {
    setDatePath() {
      this.datePath = this.userUploadPath + '/' + this.getDateFolder()
      // e.g. /var/www/html/al-lng/api/uploads/jarrar000001/240901
      this.$emit('toggle-upload')
      // use setDatePath in store to create in backend , the datePath in backend is for distinct user
      this.$store.dispatch('upload/setDatePath', {'path': this.datePath})
    }
  },
  mixins: [dateFolder]
}
</script>

<style scoped>
#upbload-publisch{
  color: #ffb74d;
}
.material-icons{
  display: inline-flex;
  vertical-align: top;
}
</style>
