<template>
  <section id="login" class="section section-login">
    <div class="row" style="margin-left: 0; margin-right: 0;">
      <div class="card-panel grey lighten-3">
        <h4 class="grey-text text-darken-2">{{ $t('auth.login') }}</h4>
        <form id="login-form" role="form" @submit.prevent="loginUser">
          <div class="input-field">
            <input type="email" id="login-email" class="validate" v-model="email" required/>
            <label for="login-email">Email</label>
          </div>
          <div class="input-field">
            <input type="password" id="login-password" class="validate" v-model="password" required/>
            <label for="login-password" data-error="Please enter your password.">{{ $t('auth.password') }}</label>
            <span class="field-icon">
            <span class="material-icons" :class="{login_password_text: isPwdLoginText , login_password_hidden: !isPwdLoginText}" name="showHide" @click="showHideLoginPwd">visibility</span></span>
          </div>
          <div id="ctrl-login" class="center">
            <button type="submit" class="btn orange lighten-2 grey-text text-darken-4 z-depth-0">{{ $t('auth.login') }}&nbsp;<i class="material-icons" style="vertical-align: -5px;">send</i></button>
            <span class="transparent-text">&nbsp;&nbsp;</span>
            <a class="btn orange lighten-2 white-text z-depth-0"
               @click="cancelCollapseLogin">
              <i class="material-icons" style="vertical-align: -5px;">cancel</i>&nbsp;{{ $t('auth.cancel') }}
            </a>
          </div>
          <forgot @onCloseOverlay="closeOverlay"></forgot>
          <div>
            <a class="transparent right z-depth-0 orange-text modal-trigger"
               data-target="modal-forgot" style="cursor: pointer; font-size: 0.8rem; bottom: 5px;"
               @onCloseOverlay="closeOverlay"
            >
              {{ forgot_password }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
/* eslint no-undef: 0 */
import Forgot from '@/components/auth/Forgot'
import userLogFile from "@/mixins/userLogFile";

export default {
  name: 'Login',
  components: {
    Forgot
  },
  data () {
    return {
      isPwdLoginText: false,
      email: null,
      password: null,
      forgot_password: this.$i18n.t('auth.forgot_password'),
      uid: null,
      username: '',
      surname: '',
      upload_folder: '',
      log_msg: null,
      userLogFilename: ''
    }
  },
  computed: {
    //
  },
  methods: {
    showHideLoginPwd () {
      this.isPwdLoginText = !this.isPwdLoginText
      if(this.isPwdLoginText) {
        document.getElementById('login-password').type = 'text';
      } else {
        document.getElementById('login-password').type = 'password';
      }
    },
    cancelCollapseLogin () {
      this.$emit('close-collapse-login')
    },
    closeOverlay () {
      $('.sidenav-overlay').css('display', 'none')
    },
    async loginUser () {
      const user = new URLSearchParams()
      user.append('login', this.email)
      user.append('password', this.password)

      // this.$store.dispatch('auth/loginUser', user)
      // to do: write to log file !!!!!!!!!
      await this.$store.dispatch('auth/loginUser', user).then((response) => {
        this.uid = response.data.user.id
        this.surname = response.data.user.surname
        this.userLogFilename = this.buildUserLogFilename(this.uid)
        this.$store.dispatch('auth/setUserLogFilename', this.userLogFilename)
        this.addLogEntry("Logged in: successfully")
        this.$store.dispatch('auth/getUserProfile', this.uid).then((response) => {
          this.upload_folder = response.data[0].upload_folder
          // console.log(this.upload_folder)
          // for now, put in state ... to do
        })
        this.error = ''
        this.username = response.data.user.username
        // this.getSetUserChatData()  ... later ij
        M.toast({ html: this.$i18n.t('footer.login_success'), classes: 'rounded orange-text' })
      }, error => {
        this.error = error.response.data.error
        if (this.error === 'invalid_credentials') {
          alert(this.$i18n.t('footer.invalid_credentials'))
        }
        // console.log('Login1: ' + error)
      })
      scroll(0, 0)
    }
  },
  mixins: [userLogFile]
}
</script>

<style scoped>
span.field-icon {
  float: right;
  position: absolute;
  right: 12px;
  cursor: pointer;
  top: 10px;
}
.login_password_text {
  color: #b71c1c;
}

.login_password_hidden {
  color: #757575;
}
</style>
