
export const STORE_UPLOADED_FILES = (state, files) => {
    state.files = files
}

// where to store data ... without filename
export const SET_DATE_PATH = (state, datePath) => {
    state.datePath = datePath
}

