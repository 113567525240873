<template>
  <section id="contact" class="section section-contact scrollspy">
    <div class="row">
      <div class="vtcnet center" style="font-size: 0.8rem">
        vtc-Net, Ibrahim Jarrar
        <br>
        T: +49 (0)228 9093476/ E-Mail: jarrar@vtc-net.de
        <br>
        jarrar@vtc-net.de
      </div>
      <div class="card-panel grey lighten-3">
        <h5 class="form-header">{{ $t('footer.fill_contact_form') }}</h5>
        <div class="input-field">
          <input type="text" id="name" class="validate" minlength="3" v-model="name"  required>
          <label for="name">{{ $t('footer.name') }}</label>
        </div>
        <div class="input-field">
          <input type="email" id="email" class="validate" v-model="from" required>
          <label for="email">{{ $t('footer.u_email') }}</label>
        </div>
        <div class="input-field">
          <input type="text"
                 onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                 id="phone" v-model="phone"  required>
          <label for="phone">{{ $t('footer.phone') }}</label>
        </div>
        <div class="input-field">
          <input type="text" id="subject" v-model="subject" required>
          <label for="subject">{{ $t('footer.subject') }}</label>
        </div>
        <div class="input-field">
          <textarea class="materialize-textarea" id="message" v-model="message"></textarea>
          <label for="message">{{ $t('footer.message') }}</label>
        </div>
        <div class="input-field">
          <label>
            <input type="checkbox" class="filled-in" name="copy_to_sender" v-model="checked" />
            <span>{{ $t('footer.copy_to_sender') }}</span>
          </label>
        </div>
        <br><br><br>
        <a class="waves-effect waves-light btn orange lighten-2 white-text"
           @click="sendMail">{{ $t('footer.send') }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint no-undef: 0 */

const toMail = 'jarrar@vtc-net.de'

export default {
  name: 'Contact',
  data () {
    return {
      to: toMail,
      name: '',
      from: '',
      phone: '',
      subject: '',
      message: '',
      checked: true,
      copy: 0
    }
  },

  methods: {
    async sendMail () {
      this.$emit('close-contact')
      this.copy = 0
      if (this.checked) {
        this.copy = 1
      }
      let payload = {
        lang: this.$i18n.locale,
        type: 'contact',
        to: this.to,
        name: this.name,
        from: this.from,
        phone: this.phone,
        subject: this.subject,
        message: this.message,
        copy: this.copy // copy to sender
      }
      await this.$store.dispatch('home/sendContactForm', payload)
        .then((response) => {
          if (response.data === true) {
            M.toast({ html: this.$i18n.t('footer.contact_success'), classes: 'rounded orange-text' })
            // this.$emit('close-contact')
          }
        })
        .catch(() => {
          //
        })
    }
  }
}
</script>

<style scoped>
.send-button{
  margin-top: 4vw;
}

</style>
