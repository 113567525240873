export default {
    user: {},
    user_role: 'none', // activator or attendee
    profile: null,
    authorized: false,
    token: '',
    error: null,
    isReady: false,
    customerNumber: null,
    isReadyToRegister: false,
    isUserLogFileCreated : false,
    userLogFilename: '',
    logFileLineLength: 0
}
