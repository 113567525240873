<template>
  <div ref="upload-form" v-if="isUploadForm">
    <!-- add drag & drop events listener to the container with class dragndrop -->
    <div
        class="dragndrop"
        @dragover.prevent="enter"
        @dragenter.prevent="enter"
        @dragleave.prevent="leave"
        @dragend.prevent="leave"
        @drop.prevent="drop"
        :class="{'dragndrop--dragged': isDraggedOver }"
    >
      <!-- label for id="file": it takes the role for search files on local machine -->
      <label for="file" class="dragndrop__button"
             :class="{ 'dragndrop__button--compact': files.length >= 1 }">
        <span><strong>+ {{ $t('upload.click') }}</strong> {{ $t('upload.click_to_select') }}</span>
      </label>
      <input type="file"
             name="files[]"
             id="file"
             class="dragndrop__input"
             ref="input"
             @change="select"
             multiple
      >
      <div v-if="files.length">
        <p class="center">
          <b>{{ $t('upload.file_list') }}</b>
          ({{ files.length }} {{ $tc('upload.files', files.length) }}):
          <span>{{ $t('upload.with_sign') }}
            <i class="material-icons grey-text text-darken-2" style="vertical-align: -5px;">remove_circle_outline</i>
            {{ $t('upload.can_rem_file') }}!
          </span>
        </p>
      </div>
      <div v-for="(file, index) in files" :key="index" class="file-listing">
        {{ file.name }}
        <span class="remove-file" @click="removeFile(index)">
          <i class="material-icons orange-text" style="vertical-align: -5px;">remove_circle_outline</i>
        </span>
      </div>
      <!-- list uploaded files -->
      <br>
      <div v-if="uploadedFiles.length">
        <h4 class="file-listing">{{ $t('upload.uploaded_files') }}</h4>
        <div v-for="(file, index) in uploadedFiles" :key="index" class="file-listing">
          <span>{{ file }}</span>
        </div>
      </div>
      <div v-if="notUploadedFiles.length">
        <h4 class="red-text text-darken-4 file-listing">{{ $t('upload.notuploaded_files') }}</h4>
            <div v-for="(file, index) in notUploadedFiles" :key="index" class="red-text text-darken-4 file-listing">
              <span>{{ file }}</span>
            </div>
      </div>
    </div>
    <div id="submit-files" class="center" style="margin: 10px" v-if="files.length">
      <div v-if="!fileListsRemove">
        <button type="button"
                class="btn orange lighten-2 grey-text text-darken-4 z-depth-0 tooltipped"
                @click="submitFiles"
                data-position="top" data-tooltip="$t('upload.upload')"
        >
          <i class="material-icons" style="vertical-align: -5px;">file_upload</i>
          {{ $t('upload.upload') }}
        </button>
      </div>
      <div v-if="fileListsRemove">
        <button type="button"
                class="btn orange lighten-2 grey-text text-darken-4 z-depth-0 tooltipped"
                @click="clearUploadArea"
                data-position="top" data-tooltip="$t('upload.clear_upload_area')"
        >
          <i class="material-icons" style="vertical-align: -5px;">delete_forever</i>
          {{ $t('upload.clear_lists') }}
        </button>
      </div>

    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import dateFolder from '@/mixins/dateFolder'
import {formBus} from "@/main";

export default {
  name: 'UploadForm',

  data() {
    return {
      files: [],
      isDraggedOver: false,
      datePath: '',
      uploadPath: '',
      fileNames: [],
      uploadedFiles: [],
      notUploadedFiles: [],
      isUploadForm: false,
      areUploaded: false,   // to hide upload button
      fileListsRemove: false
    }
  },

  mounted: function () {
    // const $ = window.jQuery
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
    // datePath: where store uploaded files
    // value = computed methode & mixins method
    this.datePath = this.sysUserUploadPath + '/' + this.getDateFolder()
    this.uploadPath = this.datePath.substring(this.datePath.lastIndexOf('uploads/')) // e.g. uploads/jarrar000001/240506
  },

  computed: {
    sysUserUploadPath() {
      // return the user upload_folder from profile database table
      return this.$store.getters['auth/profile'].upload_folder
    }
  },
  beforeMount () {
    formBus.$on('toggleUploadForm', (isHeader) => {
      this.isUploadForm = !isHeader
    })
    formBus.$on('hideUploadForm', () => {
      this.isUploadForm = false
    })
  },

  methods: {
    enter() {
      this.isDraggedOver = true
    },
    leave() {
      this.isDraggedOver = false
    },
    drop(e) {
      this.leave()
      // console.log(e.dataTransfer.files)   ... use dataTransfer object to show dragged files
      this.handleFilesUpload(e.dataTransfer.files)
    },
    select(e) {
      // select but not drag and drop event
      console.log('Trusted Select: ' + e.isTrusted)
      // console.log(this.$refs.input.files)  ... input file per selection use ref for selection to show the files
      // console.log(e.target.files)
      this.handleFilesUpload(e.target.files)
    },
    isFileExists(file) {
      let registered = this.files
      for (let i = 0; i < registered.length; i++) {
        if (registered[i].name === file.name) {
          alert(this.$i18n.t('upload.file_is_registered'))
          return true
        }
      }
      return false
    },
    handleFilesUpload(files) {
      for (let i = 0; i < files.length; i++) {
        if (!this.isFileExists(files[i])) {
          this.files.push(files[i]);
        }
      }
    },
    removeFile(index) {
      this.files.splice(index, 1)
    },
    submitFiles() {
      const formData = new FormData();
      let files = this.$refs.input.files
      let path = this.uploadPath  // e.g. uploads/jarrar000001/240506
      console.log(path)
      let nFiles = files.length
      formData.append("path", path)
      for (let index = 0; index < nFiles; index++) {
        formData.append("files[]", files[index])
      }
      console.log(formData.get(path))
      console.log(files)
      this.submitFilesToUpload(formData)
    },
    async submitFilesToUpload(formData) {
      await this.$store.dispatch('upload/uploadFiles', formData).then((response) => {
        console.log(response)
        this.uploadedFiles = response.data.uploaded
        console.log(response.data.uploaded)
        this.notUploadedFiles = response.data.notuploaded
        console.log(response.data.notuploaded)
        if(this.uploadedFiles.length || this.notUploadedFiles.length) {
          this.fileListsRemove = true
        }
      })
    },
    clearUploadArea (){
      this.files = []
      this.notUploadedFiles = []
      this.uploadedFiles = []
      this.areUploaded= false
      this.fileListsRemove= false
    }
  },
  mixins: [dateFolder]
}
</script>

<style scoped lang="scss">
.dragndrop {
  --dragdrop-min-height: 200px;
  width: 100%;
  min-height: var(--dragdrop-min-height);
  background: #D4D3B5;
  position: relative;
  border: 3px solid rgba(0, 0, 0, .2);
  padding: 10px;

  &__input {
    display: none; /* else: we see the standard search button and the comment  to select file */
  }

  &--dragged {
    border-color: #222;
  }
}

.dragndrop__button {
  display: block;
  font-size: 1.1em;
  color: #333;
  vertical-align: middle;
  text-align: center;
  margin: calc(var(--dragdrop-min-height) / 2) 20px;

  &--compact {
    /* background-color: darkgreen; */
    margin: calc(var(--dragdrop-min-height) / 10) 20px;
  }
}

.dragndrop__button span {
  padding: 5px;
  border: 3px dashed rgba(0, 0, 0, .2);
  border-radius: 3px;
  background-color: #ffcc80;
}

.dragndrop__button:hover {
  text-decoration: none;
  color: #777;
  cursor: pointer;
}

.file-listing {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
}

span.remove-file {
  cursor: pointer;
  float: right;
}
</style>
