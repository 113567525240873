
export const SET_USER = (state, user) => {
    state.user = user
}

export const SET_TOKEN = (state, token) => {
    state.token = token
}

export const SET_AUTHORIZED = (state, isAuth) => {
    state.authorized = isAuth
}

export const SET_PROFILE = (state, profile) => {
    state.profile = profile
}

export const SET_ERROR = (state, content) => {
    state.error = content
}

export const SET_CUSTOMER_NUMBER = (state, number) => {
    state.customerNumber = number
}

export const READY_TO_CONTINUE = (state, isReady ) => {
    state.isReady = isReady
}

// status: true for permitted and false for rejected
export const SET_ALLOW_REGISTER = (state, status) => {
    state.isReadyToRegister = status
}

export const SET_USER_LOG_FILE_CREATED = (state, status) => {
    state.isUserLogFileCreated = status
}

export const SET_USER_LOG_FILENAME = (state, name) => {
    state.userLogFilename = name
}

export const SET_USER_LOG_FILE_LENGTH = (state, number) => {
    state.logFileLineLength = number
}

export const INIT_STATE_PARAMS = (state) => {
    state.user = {}
    state.user_role = 'none'
    state.profile = null
    state.authorized = false
    state.token = ''
    state.error = null
    state.ready = false
    state.isReadyToRegister = false
    state.userLogFilename = ''
    state.logFileLineLength = 0
}


