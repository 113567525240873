<template>
<div>
  <Login @close-collapse-login="closeCollapseLogin"></Login>
</div>
</template>

<script>
/* eslint no-undef: 0 */
import Login from '@/components/auth/Login'
export default {
  name: 'LoginStarter',
  components: {
    Login
  },
  methods: {
    closeCollapseLogin(){
      console.log('Collapse Profile Clicked')
      $('#header-login').click()
    },
  }
}
</script>

<style scoped>

</style>
