<template>
  <div>
    <h5 class="orange-text text-lighten-2 center" v-if="this.$i18n.locale=='de'">{{ $t('company') }}, Ibrahim Jarrar</h5>
    <h5 class="orange-text text-lighten-2 center" v-if="this.$i18n.locale=='ar'">{{ $t('company') }}, ابراهيم جرار</h5>
    <p class="blue-grey-text text-lighten-3" :class="{'center': this.$i18n.locale==='ar'}" v-html="$t('footer.header_text')">
    </p>
  </div>
</template>

<script>
export default {
  name: 'FooterText'
}
</script>

<style scoped>

</style>
