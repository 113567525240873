<template>
  <a href="#page-footer"
     class="tooltipped"
     data-position="bottom" :data-tooltip="$t('nav.downward')"
     id="more"
  >
    <i class="material-icons change-direction orange-text text-lighten-2">navigation</i>
  </a>
</template>

<script>
export default {
  name: 'NanAuth',
  mounted () {
    const $ = window.jQuery
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  }
}
</script>

<style scoped>
.change-direction {
  -ms-transform: rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
  transform: rotate(180deg);
}
</style>
