<template>
  <section id="register" class="register" v-if="!authorized">
    <div class="row" style="margin-left: 0; margin-right: 0;">
      <div class="card-panel grey lighten-3">
        <h4 class="grey-text text-darken-2">{{ $t('auth.sign_up') }}</h4>
        <form id="register-form" role="form" @submit.prevent="registerUser">
          <div class="col s12 l3" style="margin-left:0; margin-right: 0">
            <label>{{ $t('auth.salutation') }}</label>
            <select class="browser-default" v-model="profile.gender">
              <option value="male" selected>{{ $t('auth.male') }}</option>
              <option value="female">{{ $t('auth.female') }}</option>
            </select>
          </div>
          <div class="input-field col s12 l4" style="margin-left:0; margin-right: 0">
            <input type="text" id="name" class="validate" v-model="name" required>
            <label for="name">{{ $t('auth.name') }}</label>
          </div>
          <div class="input-field col s12 l5">
            <input type="text" id="surname" class="validate" v-model="surname" required>
            <label for="surname">{{ $t('auth.surname') }}</label>
          </div>
          <div class="input-field col s12 m12">
            <input type="text" id="organization" v-model="profile.organization">
            <label for="organization">{{ $t('auth.organization') }}</label>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12">
              <input type="email" id="email" class="validate" v-model="email" required>
              <label for="email">Email</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 m4">
              <label for="phone">{{ $t('auth.phone') }}</label>
              <input type="text"
                     onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="phone" class="validate" v-model="profile.phone" required>
            </div>
            <div class="input-field col s12 m4">
              <label for="mobile">{{ $t('auth.mobile') }}</label>
              <input type="text"
                     onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="mobile" class="validate" v-model="profile.mobile">
            </div>
            <div class="input-field col s12 m4">
              <label for="fax">{{ $t('auth.fax') }}</label>
              <input type="text"
                     onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="fax" class="validate" v-model="profile.fax">
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s4 m2">
              <label for="postal-code">{{ $t('auth.postal') }}</label>
              <input type="text" id="postal-code" class="validate" v-model="profile.postal" required>
            </div>
            <div class="input-field col s8 m4">
              <label for="location">{{ $t('auth.physical_address') }}</label>
              <input type="text" id="location" class="validate" v-model="profile.location" required>
            </div>
            <div class="input-field col s9 m4">
              <label for="street">{{ $t('auth.street') }}</label>
              <input type="text" id="street" class="validate" v-model="profile.street" required>
            </div>
            <div class="input-field col s3 m2">
              <label for="hous-number">{{ $t('auth.number') }}</label>
              <input type="text" id="hous-number" class="validate" v-model="profile.house_number" required>
            </div>
          </div>
          <!-- Password -->
          <div class="row">
            <div class="input-field col s12 l6">
              <input class="validate" type="password" name="password" id="password" minlength="9" v-model="password"
                     required>
              <label for="password">{{ $t('auth.password') }}</label>
              <span class="field-icon">
                <span class="material-icons" :class="{'password_text': isPwdText , password_hidden: !isPwdText}"
                      @click="showHidePwd">visibility</span></span>
            </div>
            <div class="input-field col s12 l6">
              <input class="validate" type="password" name="password_confirmation" id="password_confirmation"
                     v-model="password_confirmation" minlength="9" required>
              <label :class="{'active':profile.password_confirmation}"
                     for="password_confirmation">{{ $t('auth.confirm_password') }}</label>
              <span class="field-icon">
                <span class="material-icons" :class="{'confirm_text': isConfirmText , confirm_hidden: !isConfirmText}"
                      @click="showHideConfirm">visibility</span></span>
            </div>
          </div>
          <!-- password status -->
          <div class="row">
            <div class="col s12 l6">
              <span class="password-status">
                {{ $t('auth.password_content')}}
                <br>
                <span v-if="password_status === 'super'">
                  <span :class="{'password-super': password_status}">{{ $t('auth.password_super')}}</span>
                </span>
                <span v-if="password_status === 'good'">
                  <span :class="{'password-good': password_status}">{{ $t('auth.password_good')}}</span>
                </span>
                <span v-if="password_status === 'middle'">
                  <span :class="{'password-middle': password_status}">{{ $t('auth.password_middle')}}</span>
                </span>
                <span v-if="password_status === 'weak'">
                  <span :class="{'password-weak': password_status}">{{ $t('auth.password_weak')}}</span>
                </span>
                <span v-if="password_status === 'refused'">
                  <span :class="{'password-refused': password_status}">{{ $t('auth.password_refused')}}</span>
                </span>
              </span>
            </div>
            <div class="col s12 l6">
              <span class="confirm-status">
                <span v-if="allow_confirm_test && password_confirm_match">
                  <span :class="{'confirm-match': password_confirm_match}">{{$t('auth.confirm_matched')}}</span>
                </span>
                <span v-if="allow_confirm_test && !password_confirm_match">
                  <span :class="{'not-match': !password_confirm_match}">{{$t('auth.confirm_not_matched')}}</span>
                </span>
              </span>
            </div>
          </div>

          <!-- End Password -->
          <div class="row">
            <div id="ctrl-register" class="center">
              <button type="submit" class="btn orange lighten-2 grey-text text-darken-4 z-depth-0">
                {{ $t('auth.register') }}&nbsp;<i class="material-icons" style="vertical-align: -5px;">send</i></button>
              <span class="transparent-text">&nbsp;&nbsp;</span>
              <a class="btn orange lighten-2 white-text z-depth-0"
                 @click="cancelCollapseRegister">
                <i class="material-icons" style="vertical-align: -5px;">cancel</i>&nbsp;{{ $t('auth.cancel') }}
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint no-undef: 0 */

import {isEmpty} from 'lodash'
import userLogFile from '@/mixins/userLogFile'

export default {
  name: 'Register',
  data() {
    return {
      isPwdText: false,
      isConfirmText: false,
      name: null,
      surname: null,
      username: null,
      email: null,
      password: null,
      password_confirmation: null,
      password_status: null,  // (status:  refused, weak, middle, good, super)
      password_confirm_match: false,
      allow_confirm_test: false,
      registration_allowed: false,
      reg_str: null,
      userLogFilename: null,
      allowed_country_code: ['.au', '.ae', '.ch', '.com', '.de', '.kw', '.net', '.qa', '.sa'],
      profile: {
        gender: 'male',
        user_id: null,
        category: 'customer',
        organization: null,
        phone: null,
        mobile: null,
        fax: null,
        country: 'unused',
        postal: null,
        location: null,
        street: null,
        house_number: null,
        folder: null
      },
      error: null,
    }
  },
  mounted() {
    $('.tooltipped').tooltip()
  },
  computed: {
    authorized() {
      return this.$store.getters['auth/authorized']
    }
  },
  methods: {
    cancelCollapseRegister() {
      this.$emit('close-collapse-register')
    },
    buildUploadFolderName(uid) {
      let base = '000000'
      let tail = base + uid
      tail = tail.substr(tail.length - 6) // five digit string with 0 leading
      this.profile.folder = this.surname.toLowerCase().replace(/\s/g, "") + tail
    },
    checkPasswordStatus() {
      let pwd = this.password
      let isSmall = 0
      let isCapital = 0
      let isNum = 0
      let isSpecial = 0

      for(let i = 0; i < pwd.length; i++) {
        if (/[a-z]/.test(pwd[i])) {
          isSmall = 1
        }
        if (/[A-Z]/.test(pwd[i])) {
          isCapital = 1
        }
        if (/[0-9]/.test(pwd[i])) {
          isNum = 1
        }
        if (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(pwd[i])) {
          isSpecial = 1
        }
      }
      let weight = 0

      weight = isSmall + isCapital + isNum + isSpecial
      if (pwd.length >= 9 && weight === 4)
        this.password_status = 'super'
      if (pwd.length >= 9 && weight === 3)
        this.password_status = 'good'
      if (pwd.length >= 9 && weight === 2)
        this.password_status = 'middle'
      if (pwd.length >= 9 && weight === 1)
        this.password_status = 'weak'
      if (pwd.length < 9)
        this.password_status = 'refused'
    },
    showHidePwd() {
      this.isPwdText = !this.isPwdText
      if (this.isPwdText) {
        document.getElementById('password').type = 'text';
      } else {
        document.getElementById('password').type = 'password';
      }
    },
    showHideConfirm() {
      this.isConfirmText = !this.isConfirmText
      if (this.isConfirmText) {
        document.getElementById('password_confirmation').type = 'text';
      } else {
        document.getElementById('password_confirmation').type = 'password';
      }
    },
    checkPasswordConfirmMatch() {
      let password = this.password
      let confirm = this.password_confirmation
      let len = this.password.length
      let iter = 0
      this.password_confirm_match = false
      this.allow_confirm_test = this.password_confirmation.length > 8;
      if(this.password.length === this.password_confirmation.length) {
        for (let i = 0; i < len; i++){
          if (password[i] === confirm[i])
          {
            iter += 1
          }
        }
        if (len === iter) {
          this.password_confirm_match = true
        }
      }
    },

    async setUserProfile() {
      await this.$store.dispatch('auth/setProfileData', this.profile).then((response) => {
        console.log('Register: SetProfile 3' + response.data)

        this.getUserProfile()
      }).catch((error) => {
        throw (error)
        // WRITE to LOG FILE
        // console.log('Register: set Profile Error');
        // console.log(error)
      })
    },
    async createUserLogPath() {
      // alert('Register: ' + this.userLogFilename)
      await this.$store.dispatch('auth/createUserLogPath', this.userLogFilename).then(()=> {
        //
      }).catch((err) => {
        console.log(err)
      })
    },
    async loginUser() {
      const logUser = new URLSearchParams()
      logUser.append('login', this.email)
      logUser.append('password', this.password)
      await this.$store.dispatch('auth/loginUser', logUser).then((response) => {
        this.profile.user_id = response.data.user.id

        this.$emit('close-collapse-register')
        // surname + uid (6-digits with leading zero)
        this.buildUploadFolderName(this.profile.user_id)  // e.g jarrar000001
        this.userLogFilename = this.buildUserLogFilename(this.profile.user_id)   // e.g userlogs/jarrar000001.log  ... mixins: userLogFile
        this.$store.dispatch('auth/setUserLogFilename', this.userLogFilename)
        this.createUserLogPath(this.userLogFilename)      // with first entry
        this.setUserProfile()
        this.error = ''
        // this.chat.username = response.data.user.username
        // this.getSetUserChatData()  ... in userChatMixin  ij later
      }, error => {
        this.error = error.response.data.error
      })
      scroll(0, 0)
    },
    // put profile data in vuex store
    async getUserProfile() {
      await this.$store.dispatch('auth/getUserProfile', this.profile.user_id).then(() => {
        // console.log('Register: getUserProfile 4: ' + this.profile.user_id)
        // reload is required to set registered user beside avatar if the avatar is used
        document.location.reload()
      })
    },
    checkUserRegistered() {
      console.log('Country Code is ...')
    },
    allowSpecificRegistration() {        // LG, AG
      let emailText = this.email.toLowerCase()
      if (emailText.includes('@lg-') || emailText.includes('@ag-')){
        this.registration_allowed = true
        this.$store.state['auth/isReadyToRegister'] = true
      }
    },
    async isGuestInRegister() {
      await this.$store.dispatch('auth/checkRegistrationAllowed', this.reg_str).then(()=>{
      }, error => {
        this.error = error.response.data.error
        console.log(this.error)
      })
    },
    buildRegCheckString() {
      let str = this.email;
      str = str.substr(str.indexOf('@') + 1)
      this.reg_str = this.name.concat(str.substr(0, str.indexOf('.')), this.surname).toLowerCase()
    },
    prepareRegistration() {
      // build the string for checking (is user allowed to register)
      this.buildRegCheckString()
      // NO CHECK FOR trusted organisation - like ag, lg -, they are granted automatically
      if (this.registration_allowed === false) {
        this.isGuestInRegister()
      }
      setTimeout(()=>{
        this.registration_allowed = this.$store.getters['auth/isRegisteringAllowed']
        if (this.registration_allowed === false) {
          alert(this.$i18n.t('auth.to_allow_reg'))
          this.cancelCollapseRegister()
        }
      }, 2000)
    },
    async registerUser() {
      this.prepareRegistration()
      await setTimeout(() => {
        if(this.registration_allowed === false) return

        const user = new URLSearchParams()
        user.append('name', this.name)
        user.append('surname', this.surname)
        user.append('username', this.name + ' ' + this.surname)
        user.append('email', this.email)
        user.append('password', this.password)
        user.append('password_confirmation', this.password_confirmation)

        this.$store.dispatch('auth/registerUser', user).then((response) => {
          console.log(response)
          this.loginUser()
          M.toast({html: this.$i18n.t('footer.registered_success'), classes: 'rounded orange-text'})
        }).catch((error) => {
          let msg = error.response.data.errors.email[0]
          if (msg.includes('taken')) {
            this.error = 'registered'
            this.cancelCollapseRegister()
            M.toast({html: this.$i18n.t('footer.registered_you_are'), classes: 'rounded orange-text'})
          }
          throw (error)
        })
      }, 2500)
    }
  },

  watch: {
    password(a, b) {
      if (!isEmpty(b)) {
        this.checkPasswordStatus()
      }
    },
    password_confirmation(a, b) {
      if (!isEmpty(b)) {
        this.checkPasswordConfirmMatch()
      }
    },
    // For AG, LG,
    email(a, b) {
      let str
      let countryCode
      if(!isEmpty(b)) {
        str = a
        countryCode = str.substr(str.lastIndexOf('.'))
        let codeArray = this.allowed_country_code
        if (codeArray.includes(countryCode)) {
          this.checkUserRegistered()
        }
        this.allowSpecificRegistration()
      }
    }
  },
  mixins: [userLogFile]
}
</script>

<style scoped>
span.field-icon {
  float: right;
  position: absolute;
  right: 12px;
  cursor: pointer;
  top: 10px;
}
.password_text {
  color: #b71c1c;
}
.password_hidden {
  color: #757575;
}
.confirm_text {
  color: #b71c1c;
}
.confirm_hidden {
  color: #757575;
}
.password-status, .confirm-status {
  position: absolute;
  margin-top: -2.0rem;
}
.confirm-match{
  color: #1b5e20;
}
.not-match{
  color: #ef9a9a;
}
/* #ef9a9a red lighten-3 */
.password-refused {
  color: #ef9a9a;
}
/* #9e9d24 lime darken-3 */
.password-weak {
  color: #9e9d24;
}
/* #558b2f light-green darken-3 */
.password-middle {
  color: #558b2f;
}
/* #33691e light-green darken-4 */
.password-good {
  color: #33691e;
}
/* #1b5e20 green darken-4 */
.password-super {
  color: #1b5e20;
}
</style>
