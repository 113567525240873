<template>
  <!-- main menu -->
  <header class="main-header">
    <!-- NAVBAR -->
    <nav id="navigation" class="transparent">
      <div class="container-fluid">
        <!--div class="nav-wrapper light-blue darken-3 z-depth-3"-->
        <div class="nav-wrapper z-depth-2">
          <router-link to="/" class="tooltipped brand-logo orange-text text-lighten-2 z-depth-0 flow-text"
                       @click.native="showHeader"
                       data-position="bottom" :data-tooltip="$t('nav.home')" v-if="isHeader">
            <span style="margin-left: 2vw; text-shadow: 3px 3px 3px #000;"
                  class="flow-text hide-on-small-only btn_on_hover">{{ $t('company') }}</span>
            <span style="text-shadow: 3px 3px 3px #000; vertical-align: 5px;"
                  class="flow-text center hide-on-med-and-up btn_on_hover">{{ $t('company') }}</span>
          </router-link>
          <!-- to be removed on production -->
          <!--
          <span style="margin-left: 200px; font-size: 20px;color: #ff0000; text-shadow: 2px 2px 2px #000"
                class="hide-on-small-only flow-text">In TEST!</span>
          -->
          <!-- to here remove -->
          <!-- sidenav trigger -->
          <a href="#" data-target="mobile-nav" class="sidenav-trigger"><i class="material-icons">menu</i></a>
          <!-- Language switcher for all displays: small, med and large -->
          <ul class="right" v-if="!authorized">
            <LanguageSwitcher></LanguageSwitcher>
          </ul>
          <!-- -------------------- these menu items for all but small ----------------------- -->
          <ul class="right hide-on-small-only">
            <!--
            <li v-if="!authorized">
              <AboutNavigation></AboutNavigation>
            </li >
            -->
            <li class="upload-starter" v-if="authorized">
              <!-- emit toggle-header-upload event in the child component UploadStarter -->
              <UploadStarter @toggle-upload="toggleHeaderUpload"></UploadStarter>
            </li>
            <!-- -->
            <li class="toggle-fullscreen">
              <ToggleFullscreen></ToggleFullscreen>
            </li>
            <li class="top-down">
              <TopDown></TopDown>
            </li>
          </ul>
          <!-- ------------------- Sidenav menu for small and med ------------------- -->
          <ul class="sidenav grey darken-4 z-depth-0 hide-on-large-only" id="mobile-nav">
            <li>
              <router-link :to="`/${$i18n.locale}/`" data-target="mobile-links">
                >
                <i class="material-icons orange-text text-lighten-2">home</i>
                <span class="orange-text text-lighten-4 left" style="font-size: 0.9em">{{ $t('nav.home') }}</span>
              </router-link>
            </li>
            <li v-if="!authorized">
              <router-link :to="`/${$i18n.locale}/about`" data-target="mobile-links">
                <i class="material-icons orange-text text-lighten-2">info</i>
                <span class="orange-text text-lighten-4 left" style="font-size: 0.9em">{{ $t('nav.about_us') }}</span>
              </router-link>
            </li>
            <li class="upload-starter" v-if="authorized">
              <router-link :to="`/${$i18n.locale}/upload`"
                           @click.native="setDatePath && setUploadFlag"
                           data-target="mobile-links">
                >
                <i class="material-icons orange-text text-lighten-2">publish</i>
                <span class="orange-text text-lighten-4 left" style="font-size: 0.9em">{{
                    $t('nav.upload_prepare')
                  }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav> <!-- Navigation -->
    <!-- Header motive if display greater than  med -->
    <div class="mainjob container-fluid" v-if="!authorized">
      <div class="row">
        <div class="hide-on-med-and-down" style="padding-top: 70px;">
          <div class="col s12 m4 left" style="margin-left: 2vw; ">
            <h1 class="flow-text">Deutsch-Arabisch</h1>
            <h1 class="flow-text">Übersetzen & Dolmetschen</h1>
            <br>
          </div>
          <div class="col s12 m4 right" style="margin-right: 2vw;">
            <h1 class="flow-text right-align">عربي ألماني</h1>
            <h1 class="flow-text right-align">ترجمة تحريرية وشفوية</h1>
            <br>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint no-undef: 0 */
import TopDown from './navParts/TopDown'
import LanguageSwitcher from '@/views/components/navParts/LanguageSwitcher'
import ToggleFullscreen from '@/views/components/navParts/ToggleFullscreen'

import UploadStarter from '@/views/components/navParts/UploadStarter'
// import AboutNavigation from "@/views/components/navParts/AboutNavigation";

import dateFolder from "@/mixins/dateFolder";
import {formBus} from "@/main";

export default {
  data() {
    return {
      isHeader: true,
      isUploadFree: false
    }
  },
  components: {
//     AboutNavigation,
//     SideNavigation,
    LanguageSwitcher,
    ToggleFullscreen,
    UploadStarter,
    TopDown
  },

  mounted() {
    // const $ = window.jQuery
    this.$nextTick(() => {
      $('.sidenav').sidenav()
      $('.tooltipped').tooltip()
      // CAROUSEL INIT
      $('.carousel.carousel-slider').carousel({fullWidth: true});

      $('.modal').modal()
    })
  },
  // props: {
  //   'authorized': {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  computed: {
    authorized() {
      return this.$store.getters['auth/authorized']
    }
  },

  methods: {
    toggleHeaderUpload() {
      this.isHeader = !this.isHeader  // toggle
      if(this.isHeader){
        this.isUploadFree = false
      } else {
        this.isUploadFree = true
      }
      formBus.$emit('toggleUploadForm', this.isHeader)
    },

    showHeader() {
      formBus.$emit('hideUploadForm')
      this.isHeader = true
    },
    setUploadFlag() {   // only f. small and med
      this.isUploadFree = true
    },
    setDatePath() {
      this.datePath = this.userUploadPath + '/' + this.getDateFolder()
      // ij20240426
      // use setDatePath in store actions to create the datePath in backend for distinct user
      this.$store.dispatch('upload/setDatePath', {'path': this.datePath})
    }
  },
  mixins: [dateFolder]
}
</script>
<style scoped lang="scss">
#navigation {
  background: url(../../../src/assets/img/nav.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 100%;
  color: white;
}
.btn_on_hover:hover {
  position: relative;
  background: #333;
  border-radius: 10px;
  padding: 7px;
  box-shadow: 0 5px 5px 0 rgba(0,0,0,0.5), 0 5px 5px 0 rgba(0,0,0,0.2);
}

.mainjob {
  background: url(../../../src/assets/img/header.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 300px;
  color: white;
}

.row .col {
  padding: 0;
}
</style>
