<template>
  <div>
    <Profile @close-collapse-profile="closeCollapseProfile"></Profile>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import Profile from '@/components/auth/Profile'
export default {
  name: 'ProfileStarter',
  components: {
    Profile
  },
  mounted () {
    this.$nextTick(() => {
      $('.collapsible').collapsible()
      this.closeCollapseProfile()
    })
  },
  methods: {
    closeCollapseProfile () {
      $('.collapsible').collapsible('close', 1)
    },
  }
}
</script>

<style scoped>

</style>
