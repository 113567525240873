<template>
  <div>
    <ul class="switcher">
      <li>
        <a class="dropdown-trigger"
           data-target="dropdown-lang">
          <img :src="`/flag_${$i18n.locale}.jpg`" alt="" style="width: 24px">
        </a>
      </li>
      <ul id="dropdown-lang" class="dropdown-content" style="font-size: 0.8rem">
        <li class="language" style="height: 20px"
            v-for="language in supportedLanguages" :key="language" @click="selectLanguage(language)">
          <img :src="`/flag_${language}.jpg`"
               alt="" style="width: 24px" class="lang-icon"> {{ $t('nav.' + language) }}
        </li>
      </ul>
    </ul>
    <!--
    <li class="input-field">

    </li>
    -->
  </div>


</template>
<script>
/* eslint-disable */
import jQuery from 'jquery'

export default {
  data () {
    return {
      language: ''
    }
  },
  mounted () {
    const $ = window.jQuery
    this.$nextTick(() => {
      $('.dropdown-trigger').dropdown()
    })
  },
  computed: {
    supportedLanguages () {
      // console.log('LNG: ' + process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','))
      return process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  },
  methods: {
    selectLanguage (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        const pathStr = this.$route.fullPath.slice(1)
        const pathStrLength = pathStr.length
        // only 2 char for lang, never root if more
        if (pathStrLength === 2) {
          this.$router.push({path: `/${locale}`})
        } else {
          this.$router.push({ params: { lang: locale } })
        }
      }
      // to load translation of select options
      window.location.reload()
    }
  }
}
</script>

<style scoped>
.lang-icon{
  margin-top: 5px;
  margin-left: 5px;
}

.switcher .dropdown-trigger i.right{
  margin-left: 7px;
}
.dropdown-content{
  min-width: 120px;
}
.dropdown-content li {
  clear: both;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  min-height: 35px;
  line-height: 35px;
  width: 100%;
  text-align: left;
}
</style>
