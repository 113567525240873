<template>
  <div>
    <div v-if="fullScreenMode">
      <a href="javascript:void(0);"
         class="waves-effect waves-block waves-light tooltipped"
         data-position="bottom" :data-tooltip="$t('nav.window_mode')"
         @click="fullScreenMode = !fullScreenMode">
        <i class="material-icons orange-text text-lighten-2">settings_overscan</i>
      </a>
    </div>
    <div v-if="!fullScreenMode">
      <a href="javascript:void(0);"
         class="waves-effect waves-block waves-light tooltipped"
         data-position="bottom" :data-tooltip="$t('nav.full_mode')"
         @click="fullScreenMode = !fullScreenMode">
        <i class="material-icons orange-text text-lighten-2">settings_overscan</i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleFullscreen',
  data () {
    return {
      fullScreenMode: false
    }
  },
  methods: {
    openFullscreen: function () {
      var elem = document.documentElement
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen()
      }
    },

    closeFullscreen: function () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  },
  mounted () {
    const $ = window.jQuery
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },
  watch: {
    fullScreenMode: function () {
      if (this.fullScreenMode === true) {
        this.openFullscreen()
      } else {
        this.closeFullscreen()
      }
    }
  }
}
</script>

<style scoped>

</style>
