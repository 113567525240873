<template>
  <div class="home">
    <section id="home-articles" v-if="!authorized">
      <article class="grid center">
        <i class="material-icons medium orange-text text-lighten-2">mic</i>
        <h5 class="grey-text text-darken-4">{{ $t('home.interpret') }}</h5>
        <ul>
          <li>{{ $t('home.justice_policy')}}</li> <!-- Justiz und Politik" -->
          <li>{{ $t('home.since_tech') }}</li>    <!-- Wissenschaft und Technik -->
          <li>{{ $t('home.act_public') }}</li>    <!-- Handel und Öffentlichkeitsarbeit -->
        </ul>
        <hr class="hide-on-med-and-up">
      </article>
      <article class="grid center">
        <i class="material-icons medium orange-text text-lighten-2">import_contacts</i>
        <h5 class="grey-text text-darken-4">{{ $t('home.translation')}}</h5>
        <ul>
          <li>{{ $t('home.certified_translation')}}</li>
          <li>{{ $t('home.documents') }}, {{ $t('home.contracts')}} {{ $t('home.and')}} {{ $t('home.certificates')}}</li>
          <li>{{ $t('home.special_translation') }}</li>
        </ul>
        <hr class="hide-on-med-and-up">
      </article>
      <article class="grid center">
        <i class="material-icons medium orange-text text-lighten-2">build</i>
        <h5 class="grey-text">{{ $t('home.aids_tools') }} ({{ $t('home.in_menu') }})</h5>
        <!--
        <h5 class="grey-text">{{ $t('home.aids_tools') }}</h5>
        -->
        <ul>
          <!--
          <li class="left-block grey-text"><i class="material-icons grey-text center" style="vertical-align: -6px;">surround_sound</i>{{ $t('home.video_chat_and') }}</li>
          -->
          <li class="left-block  grey-text"><i class="material-icons grey-text center" style="vertical-align: -6px;">publish</i>{{ $t('home.upload_doc') }}</li>
          <li class="left-block grey-text">{{ $t('home.logged_only') }}</li>
        </ul>
      </article>
    </section>
    <div class="fixed-action-btn hide-on-small-and-down">
      <a href="#" class="btn-floating orange lighten-2 btn-large tooltipped" data-position="left"
         :data-tooltip="$t('home.navigation')" Style="opacity:0.7">
        <i class="material-icons">navigation</i>
      </a>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
// import Footer from '@/views/components/Footer'


export default {
  name: 'Home',
  components: {
    // Footer
    //
  },
  mounted() {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },
  computed: {
    authorized () {
      return this.$store.getters['auth/authorized']
    },
  }
}
</script>
<style scoped>
@media screen and (min-width: 768px) {
  #home-articles{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  article{
    flex: 0 1 32%;
    background: #fff;
    margin-bottom: 10px;
    padding: 20px;
    box-sizing: border-box;
    transition: flex-basis 0.2s linear;
  }

  article h2{
    text-align: center;
    font-size: 20px;
    margin: 10px 0;
  }
}
</style>
