import Vue from 'vue'
import Vuex from 'vuex'
import createCache from 'vuex-cache'
import store from '../vuex'
import createPersistedState from 'vuex-persistedstate'

// use js-cookie for production
// import * as Cookies from "js-cookie";

Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [createCache()],
  plugins: [createPersistedState(), createCache()],
  modules: store
})
