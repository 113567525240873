<template>
  <section id="profile" class="section section-profile">
    <div class="row" style="margin-left: 0; margin-right: 0;">
      <div class="card-panel grey lighten-3">
        <h4 class="grey-text text-darken-2">{{ $t('footer.edit_profile') }}</h4>
        <form id="register-form" role="form" @submit.prevent="updateUser">
          <div class="col s12 l3" style="margin-left:0; margin-right: 0">
            <label>{{ $t('auth.salutation') }}</label>
            <select class="browser-default" v-model="gender">
              <option value="male" selected>{{ $t('auth.male') }}</option>
              <option value="female">{{ $t('auth.female') }}</option>
            </select>
          </div>
          <div class="input-field col s12 l4" style="margin-left:0; margin-right: 0">
            <label class="active" for="name">{{ $t('auth.name') }}</label>
            <input type="text" id="name" class="validate" v-model="name" required>
          </div>
          <div class="input-field col s12 l5">
            <label class="active" for="surname">{{ $t('auth.surname') }}</label>
            <input type="text" id="surname" class="validate" v-model="surname" required>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12">
              <label class="active" for="email">Email</label>
              <input type="email" id="email" class="validate" v-model="email" required>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 l6">
              <label class="active" for="password">{{ $t('auth.password') }}</label>
              <input type="password" id="password" class="validate" minlength="9" v-model="password" required>
            </div>
            <div class="input-field col s12 l6">
              <label :class="{ 'active': password_confirmation }" for="password_confirmation">{{ $t('auth.confirm_password') }}</label>
              <input type="password" id="password_confirmation" class="validate" minlength="9" v-model="password_confirmation" required>
            </div>
          </div>
          <div class="input-field col s12 m12">
            <label :class="{ 'active': organization }" for="organization">{{ $t('auth.organization') }}</label>
            <input type="text" id="organization" v-model="organization">
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 m4">
              <label :class="{ 'active': phone }" for="phone">{{ $t('auth.phone') }}</label>
              <input type="text"
                     onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="phone" class="validate" v-model="phone">
            </div>
            <div class="input-field col s12 m4">
              <label :class="{ 'active': mobile }" for="mobile">{{ $t('auth.mobile') }}</label>
              <input type="text"
                     onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="mobile" class="validate" v-model="mobile">
            </div>
            <div class="input-field col s12 m4">
              <label :class="{ 'active': fax }" for="fax">{{ $t('auth.fax') }}</label>
              <input type="text"
                     onkeydown="return ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode == 8))"
                     id="fax" class="validate" v-model="fax">
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s4 m2">
              <label :class="{ 'active': postal }" for="postal-code">{{ $t('auth.postal') }}</label>
              <input type="text" id="postal-code" class="validate" v-model="postal" required>
            </div>
            <div class="input-field col s8 m4">
              <label :class="{ 'active': location }" for="location">{{ $t('auth.physical_address') }}</label>
              <input type="text" id="location" class="validate" v-model="location" required>
            </div>
            <div class="input-field col s9 m4">
              <label :class="{ 'active': street }" for="street">{{ $t('auth.street') }}</label>
              <input type="text" id="street" class="validate" v-model="street" required>
            </div>
            <div class="input-field col s3 m2">
              <label :class="{ 'active': house_number }" for="house-number">{{ $t('auth.number') }}</label>
              <input type="text" id="house-number" class="validate" v-model="house_number">
            </div>
          </div>
          <div id="ctrl-profile" class="center">
            <button type="submit" class="btn orange lighten-2 grey-text text-darken-4  z-depth-0">{{ $t('auth.save') }}&nbsp;<i
                class="material-icons" style="vertical-align: -5px;">send</i></button>
            <span class="transparent-text">&nbsp;&nbsp;</span>
            <a class="btn orange lighten-2 white-text z-depth-0"
               @click="cancelCollapseProfile">
              <i class="material-icons" style="vertical-align: -5px;">cancel</i>&nbsp;{{ $t('auth.cancel') }}
            </a>
            <span class="transparent-text">&nbsp;&nbsp;</span>
            <a id="delete-profile" class="btn orange lighten-2 white-text  z-depth-0 tooltipped"
               data-position="bottom" :data-tooltip="$t('footer.delete_account')"
               @click="deleteUser">
              <i class="material-icons" style="vertical-align: -5px;">delete</i>&nbsp;{{ $t('auth.delete') }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint no-undef: 0 */
import {isEmpty} from 'lodash'

export default {
  name: 'Profile',
  data() {
    return {
      name: null,
      surname: null,
      username: null,
      email: null,
      password: null,
      password_confirmation: null,
      // profile data
      gender: 'male',
      category: 'customer',
      organization: null,
      phone: null,
      mobile: null,
      fax: null,
      country: 'unused',
      postal: null,
      location: null,
      street: null,
      house_number: null,

      user_id: null,
      isProfileChanged: false,
      error: '',
      isDisabled: process.env.VUE_APP_UPDATE_USER
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    profile() {
      return this.$store.getters['auth/profile']
    }
  },
  mounted() {
    $('.tooltipped').tooltip()

    this.name = this.user.name
    this.surname = this.user.surname
    this.username = this.user.username
    this.email = this.user.email
    this.password = this.user.password
    // profile data
    if (this.profile !== null) {
      this.gender = this.profile.gender
      this.category = this.profile.category
      this.organization = this.profile.organization
      this.phone = this.profile.phone
      this.mobile = this.profile.mobile
      this.fax = this.profile.fax
      this.country= 'unused'
      this.postal = this.profile.postal
      this.location = this.profile.location
      this.street = this.profile.street
      this.house_number = this.profile.house_number
    }
  },
  methods: {
    showHidePwd(){
      $('#password').type
    },
    cancelCollapseProfile() {
      this.$emit('close-collapse-profile')
    },
    async updateUser() {
      if (this.isProfileChanged) {
        const payload = {
          'id': this.user.id,
          'name': this.name,
          'surname': this.surname,
          'username': this.username,
          'email': this.email,
          'password': this.password,
          'password_confirmation': this.password_confirmation,
          'gender': this.gender,
          'organization': this.organization,
          'category': this.category,
          'phone': this.phone,
          'mobile': this.mobile,
          'fax': this.fax,
          'postal': this.postal,
          'location': this.location,
          'street': this.street,
          'house_number': this.house_number,
        }
        // to update user table and profile table in DB
        await this.$store.dispatch('auth/updateUser', payload).then(() => {
          this.$store.dispatch('auth/logout')
          const logUser = new URLSearchParams()
          logUser.append('login', this.email)
          logUser.append('password', this.password)
          this.$store.dispatch('auth/loginUser', logUser).then((response) => {
            // console.log(response.data.user)
            this.user_id = response.data.user.id
            this.error = ''
          })

          M.toast({html: this.$i18n.t('auth.profile_updated'), classes: 'rounded orange-text'})
        })
      }
    },

    async deleteUser() {
      await this.$store.dispatch('auth/deleteUser', this.user).then((response) => {
        // console.log('User and Profile are deleted')
        console.log(response.data)
        this.$store.dispatch('auth/logout')
        M.toast({html: this.$i18n.t('footer.user_deleted'), classes: 'rounded orange-text'})
      }).catch((error) => {
        // console.log('User to delete ERROR')
        console.log(error.response.data.error)
      })
    },
    async deleteUserProfile() {
      MaterialDialog.dialog(
          this.$i18n.t('auth.sure_delete_account'),
          {
            title: this.$i18n.t('warning'),
            modalType: '',
            buttons: {
              close: {
                className: 'grey',
                text: this.$i18n.t('cancel'),
                callback: () => {
                }
              },
              confirm: {
                className: 'pink',
                text: this.$i18n.t('yes'),
                modalClose: true,
                callback: () => {
                  this.$store.dispatch('auth/deleteProfile', this.profile).then(() => {
                    this.deleteUser()
                  })
                }
              }
            }
          }
      )
    }
  },
  watch: {
    name(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
      // console.log('new: %s, old: %s', a, b)
    },
    surname(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    username(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    email(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    password(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    password_confirmation(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    gender(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    organization(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
      // console.log('new: %s, old: %s', a, b)
    },
    phone(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    mobile(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    fax(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    postal(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    location(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    street(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    house_number(a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    user_id () {
      // store get new user profile data
      this.$store.dispatch('auth/getUserProfile', this.user_id)
    }
  }
}
</script>

<style scoped>

</style>
